const awsmobile = {
  Auth: {
    region: "us-east-2",
    userPoolId: "us-east-2_f23Trygsj",
    userPoolWebClientId: "4moe7sndf6fgk58s5qr4uiih92",
    oauth: {
      domain: "nene-dev.auth.us-east-2.amazoncognito.com",
      redirectSignIn: "https://dev.auntnenescreations.com/",
      scope: ["profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignOut: "https://dev.auntnenescreations.com/",
      responseType: "code",
    },
  },
};

export default awsmobile;

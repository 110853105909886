import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Amplify } from "aws-amplify";
import AmplifyVue from "@aws-amplify/ui-vue";
import awsmobile from "@/aws-exports";

import "./scss/styles.scss";

Amplify.configure(awsmobile);

createApp(App).use(store).use(router).use(AmplifyVue).mount("#app");

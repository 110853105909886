
import "@aws-amplify/ui-vue/styles.css";
import { Options, Vue } from "vue-class-component";
import ProductCards from "@/components/ProductCards.vue"; // @ is an alias to /src

@Options({
  components: {
    ProductCards,
  },
})
export default class HomeView extends Vue {}

import axios from "axios";
import { Product } from "../models/Product";

export async function getProducts(): Promise<Product[]> {
  const response = await axios.get(
    "https://03rfmc9yrh.execute-api.us-east-2.amazonaws.com/dev/product"
  );

  return response.data.items;
}
